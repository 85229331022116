export class DashboardSummary {
  totalDoctors: string;
  totalPatients: string;
  totalDoctorAppointments: string;
  totalNursingAppointments: string;
  totalSales: string;
  todayDoctorAppointments: string;
  todayNursingAppointments: string;
  todaySales: string;
}
